
import { Component, Vue } from "vue-property-decorator";
import OrderStatusChageDialog from "@/components/organisms/order/OrderStatusChageDialog.vue";
import { Validations } from "vuelidate-property-decorators";
import { integer, required } from "vuelidate/lib/validators";

@Component({
  components: {
    OrderStatusChageDialog,
  },
})
export default class MixinOptionItemEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //名称
      name: { required },
      //表示順
      displayOrder: { required, integer },
      //種別
      optionType: { required },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 名称の入力エラーメッセージを返します.
   */
  get nameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.name?.$dirty) return errors;
    !this.$v.item.name?.required && errors.push("名称を入力してください.");
    return errors;
  }
  /**
   * プロパティの入力エラーメッセージを返します.
   */
  get propertiesErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.properties?.$dirty) return errors;
    !this.$v.item.properties?.required &&
      errors.push("プロパティを入力してください.");
    return errors;
  }
  /**
   * 種別の入力エラーメッセージを返します.
   */
  get optionTypeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.optionType?.$dirty) return errors;
    !this.$v.item.optionType?.required &&
      errors.push("種別を入力してください.");
    return errors;
  }
  /**
   * 表示順の入力エラーメッセージを返します.
   */
  get displayOrderErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.displayOrder?.$dirty) return errors;
    !this.$v.item.displayOrder?.required &&
      errors.push("表示順を入力してください.");
    !this.$v.item.displayOrder?.integer && errors.push("表示順は数値のみです.");
    return errors;
  }
}
