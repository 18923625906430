






















































import { Component, Ref, Mixins } from "vue-property-decorator";
import { OptionItem } from "@/graphql/client";
import { NotificationState } from "@/store/modules/notification";
import optionService from "@/service/optionService";
import OptionItemDeleteDialog from "@/components/organisms/option_item/OptionItemDeleteDialog.vue";
import OptionItemEditDialog from "@/components/organisms/option_item/OptionItemEditDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";

@Component({
  components: {
    OptionItemDeleteDialog,
    OptionItemEditDialog,
  },
})
export default class OptionItemList extends Mixins(MixinFormatter) {
  @Ref() readonly editDialog!: OptionItemEditDialog;
  @Ref() readonly deleteDialog!: OptionItemDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: OptionItem[] = [];
  readonly headers = [
    {
      text: "名称",
      value: "name",
    },
    {
      text: "種別",
      value: "optionType",
      width: "20%",
      formatter: this.getOptionTypeLabel,
    },
    {
      text: "表示順",
      value: "displayOrder",
      width: "15%",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  searchKeyword = "";
  loadingDataGrid = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    optionService.allOptionItems().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }

  /**
   * オプションアイテム編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(optionService.defaultOptionItem);
  }

  /**
   * オプションアイテム編集ダイアログを表示します.
   */
  public openEditDialog(item: OptionItem): void {
    this.editDialog.open(item);
  }

  /**
   * オプションアイテム編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: OptionItem): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("オプションアイテムを更新しました！");
  }

  /**
   * オプションアイテム編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: OptionItem): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("オプションアイテムを登録しました！");
  }

  /**
   * オプションアイテム削除ダイアログを表示します.
   */
  public openDeleteDialog(item: OptionItem): void {
    this.deleteDialog.open(item);
  }

  /**
   * オプションアイテム削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: OptionItem): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("オプションアイテムを削除しました！");
  }
}
