







































import { Order, OrderState } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import orderService from "@/service/orderService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class OrderStatusChangeDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Order = orderService.defaultOrder;
  action: {
    value: string;
    label: string;
    beforeOrderStatus: OrderState[];
  } | null = null;
  message = "注文ステータスを変更してもよろしいですか？";
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(
    item: Order,
    action: {
      value: string;
      label: string;
      beforeOrderStatus: OrderState[];
    }
  ): void {
    this.item = item;
    this.action = action;

    switch (this.action.value) {
      case "transitionToPaid": //決済済にする
        this.message = "「決済済」にしてもよろしいですか？";
        break;
      case "transitionToGettingReady": //配送準備を開始する
        this.message = "「配送準備中」にしてもよろしいですか？";
        break;
      case "transitionToImportingDeliverySlip": //配送伝票取り込み中にする
        this.message = "「配送伝票取り込み中」にしてもよろしいですか？";
        break;
      case "transitionToImportedDeliverySlip": //配送伝票取り込み済にする
        this.message = "「配送伝票取り込み済」にしてもよろしいですか？";
        break;
      case "transitionToDeliveryCompleted": //発送完了にする
        this.message = "「発送完了」にしてもよろしいですか？";
        break;
      case "transitionToRefunding": //返金処理を開始する
        this.message = "「返金処理中」にしてもよろしいですか？";
        break;
      case "transitionToPendingForRejection": //注文を保留する
        this.message = "「保留」にしてもよろしいですか？";
        break;
      case "transitionToCanceled": //キャンセルする
        this.message = "「キャンセル」してもよろしいですか？";
        break;
      case "transitionToDeleted": //注文を削除する
        this.message = "「注文を削除」してもよろしいですか？";
        break;
      case "transitionToWaitingForAuthDeferredPayment": //後払審査待ちにする
        this.message = "「後払審査待ち」にしてもよろしいですか？";
        break;
      case "transitionToWaitingForBankTransfer": //銀行振込待ちにする
        this.message = "「銀行振込待ち」にしてもよろしいですか？";
        break;
      case "transitionToWaitingForCreditCardPayment": //クレジットカード決済待ちにする
        this.message = "「クレジットカード決済待ち」にしてもよろしいですか？";
        break;
    }

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  async updateStatus(): Promise<void> {
    this.isProgressing = true;

    try {
      switch (this.action?.value) {
        case "transitionToPaid":
          //決済済にする
          orderService
            .transitionToPaid(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToGettingReady": //配送準備を開始する
          //配送準備中にする
          orderService
            .transitionToGettingReady(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToImportingDeliverySlip": //配送伝票取り込み中にする
          //配送伝票取り込み中にする
          orderService
            .transitionToImportingDeliverySlip(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToImportedDeliverySlip": //配送伝票取り込み済にする
          //配送伝票取り込み中にする
          orderService
            .transitionToImportedDeliverySlip(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToDeliveryCompleted": //発送完了にする
          ///発送完了にする
          orderService
            .transitionToDeliveryCompleted(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToRefunding": //返金処理を開始する
          ///返金処理中にする
          orderService
            .transitionToRefunding(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToPendingForRejection": //注文を保留する
          ///NGのため保留にする
          orderService
            .transitionToPendingForRejection(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToCanceled": //キャンセルする
          ///キャンセル済にする
          orderService
            .transitionToCanceled(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToDeleted": //注文を削除する
          ///削除済にする
          orderService
            .transitionToDeleted(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToWaitingForAuthDeferredPayment": //後払審査待ちにする
          orderService
            .transitionToWaitingForAuthDeferredPayment(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToWaitingForBankTransfer": //銀行振込待ちにする
          orderService
            .transitionToWaitingForBankTransfer(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        case "transitionToWaitingForCreditCardPayment": //クレジットカード決済待ちにする
          orderService
            .transitionToWaitingForCreditCardPayment(this.item)
            .then((result) => {
              this.isProgressing = false;
              this.notifySuccess(result as Order);
            })
            .catch((err) => {
              console.log(err);
              this.notifyError("注文ステータスを変更できませんでした");
            });
          break;
        default:
          this.notifyError("注文ステータスを変更できませんでした");
          break;
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 更新成功
   */
  @Emit("onSuccess")
  public notifySuccess(updated: Order): Order {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
