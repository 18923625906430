










import SettingSubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import OptionItemList from "@/components/organisms/option_item/OptionItemList.vue";

export default {
  components: {
    SettingSubPageTemplate,
    OptionItemList,
  },
};
