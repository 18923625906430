




































































































































import { OptionItem, Property } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import propertyService from "@/service/propertyService";
import optionService from "@/service/optionService";
import typeService from "@/service/typeService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import ImagePicker from "@/components/molecules/ImagePicker.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/option_item/MixinOptionItemEditDialogValidator.vue";

@Component({
  components: {
    ProgressLinear,
    ImagePicker,
  },
})
export default class OptionItemEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly imagePicker!: ImagePicker;
  @Ref() readonly editForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  properties: Property[] = [];
  isOpen = false;
  isProgressing = false;
  item: OptionItem = optionService.defaultOptionItem;
  optionTypes = typeService.optionTypes;
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    propertyService.allProperties().then((items) => {
      this.properties = items;
    });
  }
  //---------------------------
  // computed
  //---------------------------
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.formatDateTime(this.item.createdAt);
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.formatDateTime(this.item.updatedAt);
  }

  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: OptionItem): void {
    this.item = optionService.defaultOptionItem;
    if (item.guid) {
      optionService.getOptionItem(item.guid).then((item) => {
        if (item) {
          this.item = item;
        }
      });
    }

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.imagePicker.reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
      console.log('TEST1');
    this.$v.$touch();
      console.log(this.$v);
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        optionService
          .updateOptionItem(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyUpdateSuccess(result as OptionItem);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError("オプションアイテムを更新できませんでした.");
          });
      } else {
        //create
        optionService
          .createOptionItem(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyCreateSuccess(result as OptionItem);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError("オプションアイテムを登録できませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: OptionItem): OptionItem {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: OptionItem): OptionItem {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
